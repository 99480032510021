<template>
  <div>
    <el-form :model="form" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :lg="18" :md="16" :xl="20" :xs="24">
          <el-col :lg="8" :sm="12" :xl="6" :xs="24">
            <el-form-item label="Review ID">
              <el-input v-model="form.reviewId" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :lg="8" :sm="12" :xl="6" :xs="24">
            <el-form-item label="BU">
              <el-select v-model="form.buList" clearable collapse-tags filterable multiple placeholder="请选择">
                <el-option label="全部" value="" />
                <el-option label="Empty" value="-1" />
                <el-option
                  v-for="item in buOptions"
                  :key="item.id"
                  :label="item.bu"
                  :value="item.bu"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="8" :sm="12" :xl="6" :xs="24">
            <el-form-item label="Review星级">
              <el-select v-model="form.reviewStartList" collapse-tags filterable multiple>
                <el-option
                  v-for="item in _getAllCommodityDict('STAR_LEVEL').reverse()"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="8" :sm="12" :xl="6" :xs="24">
            <el-form-item label="Style">
              <virtualized-select
                v-model="form.styleList"
                :options="styleOptions"
                :props="{
                  label: 'styleName',
                  value: 'styleName',
                }"
                collapse-tags
                multiple
                placeholder="请选择Style"
              />
            </el-form-item>
          </el-col>
          <el-col v-if="showRow" :lg="8" :sm="12" :xl="6" :xs="24">
            <el-form-item label="定位">
              <el-select v-model="form.positionList" collapse-tags filterable multiple value-key="id">
                <el-option
                  v-for="item in positionList"
                  :key="item.id"
                  :label="item.position"
                  :value="item.position"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="showRow" :lg="8" :sm="12" :xl="6" :xs="24">
            <el-form-item label="标签">
              <el-cascader
                ref="queryHandle"
                v-model="form.labelsId"
                :options="tagData"
                :props=" { multiple: true,checkStrictly: true, value: 'id', label: language === 'en-US' ? 'labelNameEN' : 'labelName', children: 'childNodes' }"
                clearable
                collapse-tags
                filterable
              >
                <template #default="{ data }">
                  <span v-if="language === 'en-US'">{{ data.labelNameEN }}</span>
                  <span v-else>{{ data.labelName }}</span>
                </template>
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col v-if="showRow" :lg="8" :sm="12" :xl="6" :xs="24">
            <el-form-item label="供应商">
              <el-select v-model="form.supplierList" collapse-tags filterable multiple value-key="id">
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.vendorName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="showRow" :lg="8" :sm="12" :xl="6" :xs="24">
            <el-form-item label="是否带图片">
              <el-select v-model="form.hasPicture" clearable filterable placeholder="请选择">
                <el-option label="否" value="0" />
                <el-option label="是" value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="showRow" :lg="8" :sm="12" :xl="6" :xs="24">
            <el-form-item label="平台">
              <el-select v-model="form.platform" :placeholder="$t('page.selectPlaceholder')" clearable filterable>
                <el-option
                  v-for="item in shopData"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="showRow" :lg="8" :sm="12" :xl="6" :xs="24">
            <el-form-item label="父ASIN">
              <virtualized-select
                v-model="form.parentAsinList"
                :options="parentAsinOptions"
                :props="{
                  label: 'parentAsin',
                  value: 'parentAsin',
                }"
                collapse-tags
                multiple
                :placeholder="$t('page.selectPlaceholder')"
              />
            </el-form-item>
          </el-col>
          <el-col v-if="showRow" :lg="8" :sm="12" :xl="6" :xs="24">
            <el-form-item label="子ASIN">
              <el-input
                v-model="form.childAsinList"
                :placeholder="$t('page.inputPlaceholder')"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col v-if="showRow" :md="16" :xs="24">
            <el-form-item label="选择日期">
              <el-date-picker
                v-model="form.date"
                :default-time="['00:00:00', '23:59:59']"
                :end-placeholder="$t('title.endtime')"
                :start-placeholder="$t('title.starttime')"
                :unlink-panels="true"
                range-separator="~"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :lg="6" :md="8" :xl="4" :xs="24">
          <el-form-item>
            <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
            <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
            <el-button v-if="showRow" key="hide" type="text" @click="showRowClick()">
              {{ $t('page.hide') }}
            </el-button>
            <el-button v-else key="expand" type="text" @click="showRowClick()">
              {{ $t('page.expand') }}
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <vxe-toolbar>
      <template #buttons>
        <el-button size="small" type="primary" @click="handleExport()">导出</el-button>
        <el-button size="small" type="primary" @click="handleImport()">上传财务新品清单</el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      v-loading="tableLoading"
      :data="tableData"
      border
      show-overflow
      align="center"
      :scroll-x="{ enabled: true }"
      :scroll-y="{ enabled: true }"
      :column-config="{ resizable: true }"
      class="mb-3"
      max-height="550px"
    >
      <vxe-table-column
        title="平台/站点/店铺"
        min-width="150"
        field="sensitiveWord"
        sortable
      >
        <template #default="scope">
          {{ scope.row.platform }}/{{ scope.row.site }}/{{ scope.row.shop }}
        </template>
      </vxe-table-column>
      <vxe-table-column
        title="留评日期"
        min-width="100"
        field="reviewTime"
        sortable
      />
      <vxe-table-column
        title="留评标题"
        field="revTitle"
        min-width="150"
      />
      <vxe-table-column
        title="review内容"
        field="reviewContent"
        min-width="500"
        sortable
      >
        <template #default="scope">
          {{ scope.row.reviewContent || scope.row.revTitle }}
        </template>
      </vxe-table-column>
      <vxe-table-column
        title="客户昵称"
        field="reviewNickName"
        min-width="100"
        sortable
      />
      <vxe-table-column
        title="Parent Asin"
        min-width="100"
        field="asin"
        sortable
      />
      <vxe-table-column
        title="Child Asin"
        min-width="110"
        field="childAsin"
        sortable
      />
      <vxe-table-column
        title="STYLE"
        min-width="100"
        field="style"
        sortable
      />
      <vxe-table-column
        title="供应商"
        min-width="100"
        field="supplier"
        sortable
      />
      <vxe-table-column
        title="定位"
        field="position"
        sortable
        min-width="100"
      />
      <vxe-table-column
        title="BU"
        min-width="100"
        field="bu"
        sortable
      />
      <vxe-table-column
        title="产品星级"
        min-width="110"
        field="productStart"
        sortable
      />
      <vxe-table-column
        title="Review星级"
        min-width="100"
        field="reviewStart"
        sortable
      >
        <template #default="scope">
          {{ scope.row.reviewStart + '星' }}
        </template>
      </vxe-table-column>
      <vxe-table-column
        title="review链接"
        field="reviewUrl"
        min-width="100"
        sortable
      >
        <template #default="scope">
          <a
            :href="scope.row.reviewUrl"
            rel="nofollow"
            style="cursor:pointer;color:#1890FF"
            target="_blank"
          >
            {{ scope.row.reviewUrl }}
          </a>
        </template>
      </vxe-table-column>
      <vxe-table-column
        title="是否带图片"
        min-width="100"
        field="hasPicture"
        sortable
      >
        <template #default="scope">
          {{ scope.row.hasPicture === 0 ? '否' : '是' }}
        </template>
      </vxe-table-column>
      <vxe-table-column
        title="标签"
        min-width="100"
        field="markLabelList"
        sortable
      >
        <template #default="scope">
          <p v-for="item in scope.row.markLabelList" :key="item.id">{{ item.labelName }}</p>
        </template>
      </vxe-table-column>
      <vxe-table-column :title="$t('page.operate')" fixed="right" field="operate" width="80">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click.native.prevent="clickLabels(scope.row)"
          >
            打标
          </el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <el-pagination
      :current-page="pager.current"
      :page-size="pager.size"
      :page-sizes="[20, 50, 100, 200, 300]"
      :total="pager.total"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="dialogFormVisibleLabels"
      title="新增标签"
      width="600px"
    >
      <div :style="{'max-height': `${maxHeight}px`,'overflow-y': 'auto','overflow-x': 'hidden'}">
        <el-form :model="addForm" label-width="100px">
          <el-row :span="12">
            <el-form-item label="review内容">
              {{ reviewContent }}
            </el-form-item>
          </el-row>
          <el-row :span="12">
            <el-col :span="24">
              <el-form-item :label="$t('title.ProblemMentioned')">
                <el-cascader
                  ref="refHandle"
                  v-model="addForm.labelsId"
                  :options="tagDataList"
                  :props=" { multiple: true,checkStrictly: true, value: 'id', label: language === 'en-US' ? 'labelNameEN' : 'labelName', children: 'childNodes' }"
                  clearable
                  collapse-tags
                  filterable
                  show-all-levels
                >
                  <template #default="{ data }">
                    <span v-if="language === 'en-US'">{{ data.labelNameEN }}</span>
                    <span v-else>{{ data.labelName }}</span>
                  </template>
                </el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelLeLabels">{{ $t('title.cancel') }}</el-button>
        <el-button :loading="labelsLoading" type="primary" @click="submitLabels">{{ $t('title.sure') }}</el-button>
      </div>
    </el-dialog>

    <DialogImport :import-visible.sync="importVisible" @refresh="queryClick" />
  </div>
</template>

<script>
import VirtualizedSelect from '@/components/VirtualizedSelect'
import DialogImport from './DialogImport.vue'
import mixin from '@/mixin/oms-mixin'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Cookies from 'js-cookie'
import { getPosition, queryStyleList, queryVendorList } from '@/api/omsorder'
import {
  allBU,
  getPlatformSiteShop,
  messagesGetLabel,
  reviewBatchMarkLabelInsert,
  reviewPage, exportReviewInfos
} from '@/api/service-management'
import qs from 'qs'
import { getParentAsin } from '@/api/select'
import dayjs from 'dayjs'

export default {
  components: {
    VirtualizedSelect, DialogImport
  },
  mixins: [mixin, commodityInfoDict],
  data() {
    return {
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      tableData: [],
      tableLoading: false,
      language: '',
      showRow: false,
      buOptions: [],
      styleOptions: [],
      positionList: [],
      tagData: [],
      vendorOptions: [],
      shopData: [],
      parentAsinOptions: [],
      form: {
        reviewId: '',
        buList: [],
        reviewStartList: ['1', '2', '3'],
        styleList: [],
        positionList: [],
        labelsId: [-1],
        supplierList: [],
        hasPicture: '',
        platform: '',
        parentAsinList: [],
        childAsinList: '',
        date: [dayjs().subtract(1, 'month').add(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss'), dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')]
      },

      labelsId: [],
      dialogFormVisibleLabels: false,
      addForm: {
        labelsId: ''
      },
      labelsLoading: false,
      labelsIdList: [],
      includeDel: true,
      tagDataList: [],
      reviewContent: '',

      importVisible: false
    }
  },
  computed: {
    queryParameter() {
      const [reviewStartTime = '', reviewEndTime = ''] = this.form.date || []
      return Object.assign({}, this.form, this.pager, {
        labelsId: [...new Set(this.form.labelsId.flat(Infinity))],
        reviewStartTime,
        reviewEndTime
      })
    },
    exportUrl() {
      return `${exportReviewInfos}?${qs.stringify(this.queryParameter, { arrayFormat: 'repeat' })}`
    },
    maxHeight() {
      return window.innerHeight - 400
    }
  },

  async mounted() {
    this.language = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
    await Promise.all([
      this._getPlatformSiteShop(),
      this._queryStyleList(),
      this._queryVendorList(),
      this._getPosition(),
      this._allBU(),
      this._messagesGetLabel(this.includeDel),
      this.getParentAsinOptions()
    ])
    await this._reviewPage()
  },
  methods: {
    async getParentAsinOptions() {
      const { datas } = await getParentAsin()
      this.parentAsinOptions = datas || []
    },
    clickLabels(row) {
      this.includeDel = false
      this._messagesGetLabel(this.includeDel)
      const { id, reviewId, reviewContent, revTitle } = row
      this.addForm = { id, reviewId }
      this.reviewContent = reviewContent || revTitle
      this.dialogFormVisibleLabels = true
    },
    // 导入
    handleImport() {
      this.importVisible = true
    },
    // 导出文件
    handleExport() {
      window.open(this.exportUrl, '_blank')
    },
    // 获取供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    // 获取style
    async _queryStyleList(id) {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // 获取Position
    async _getPosition() {
      const { datas } = await getPosition()
      this.positionList = datas
    },
    // 获取标签
    async _messagesGetLabel(params) {
      const { datas } = await messagesGetLabel(params)
      if (this.includeDel === true) {
        datas.unshift({ id: -1, labelNameEN: 'Not label', labelName: '无标签' })
        this.tagData = datas
      } else {
        this.tagDataList = datas
      }
    },
    // 获取平台
    async _getPlatformSiteShop() {
      const { datas } = await getPlatformSiteShop()
      this.shopData = JSON.parse(datas)
    },
    // 查询bu
    async _allBU() {
      const { datas } = await allBU()
      this.buOptions = []
      datas.map(e => {
        if (e.userName === this.$store.getters.name ? this.$store.getters.name : '') {
          this.form.buList.push(e.bu)
        }
        const index = this.buOptions.findIndex((value) => value.bu === e.bu)
        index === -1 ? this.buOptions.push(e) : ''
      })
    },
    // 点击查询获取信息
    queryClick() {
      this.pager.current = 1
      this._reviewPage()
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.pager.current = 1
      Object.assign(this.form, this.$options.data.call(this).form)
      this._reviewPage()
    },
    // 默认查询
    async _reviewPage() {
      try {
        this.tableLoading = true
        const { datas } = await reviewPage(this.queryParameter)
        this.tableData = datas.records
        this.pager = datas.pager
      } finally {
        this.tableLoading = false
      }
    },
    handleSizeChange(val) {
      this.pager.size = val
      this._reviewPage()
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._reviewPage()
    },
    submitLabels() {
      this.$nextTick(() => {
        const data = this.$refs['refHandle'].getCheckedNodes(true)
        const arr = []
        // 选出第二层
        data.map(e => {
          if (e.level === 2) {
            arr.push(e.value)
          }
        })
        if (arr.length === 0) {
          this.$alert('请选择二级标签打标', this.$t('page.Prompt'), {
            confirmButtonText: this.$t('page.sure')
          })
          return
        }
        const { id, reviewId } = this.addForm
        const obj = Object.assign({}, { labelId: arr, reviewExtendId: id, reviewId })
        this._reviewBatchMarkLabelInsert([obj])
      })
    },
    // 更新状态
    async _reviewBatchMarkLabelInsert(data) {
      try {
        this.labelsLoading = true
        const { code, msg } = await reviewBatchMarkLabelInsert(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
        this._reviewPage()
        this.dialogFormVisibleLabels = false
        Object.assign(this.addForm, this.$options.data.call(this).addForm)
      } finally {
        this.labelsLoading = false
        this.dialogFormVisibleLabels = false
        Object.assign(this.addForm, this.$options.data.call(this).addForm)
      }
    },
    cancelLeLabels() {
      Object.assign(this.addForm, this.$options.data.call(this).addForm)
      this.dialogFormVisibleLabels = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content {
  .el-cascader {
    width: 100%;
    .el-cascader__search-input {
      height: 20px;
    }
  }
}

</style>
