<template>
  <div>
    <el-dialog
      title="上传财务新品清单"
      :visible.sync="importVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      width="399px"
      :before-close="cancel"
      top="15vh"
    >
      <div style="margin-bottom: 7px;">
        <span style="color: #606266;font-size: 12px;line-height: 1.5;letter-spacing: 0.5px;">
          请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除，单次导入的数据不超过200条
        </span>
        <el-link type="primary" style="font-size: 12px;" @click="downloadTemplate">下载模板</el-link>
      </div>
      <el-upload
        ref="uploadExcel"
        action=""
        accept=".xlsx,.xls,.csv"
        class="upload-excel"
        name="excel"
        :on-exceed="handleExceed"
        :limit="1"
        drag
        :auto-upload="false"
      >
        <i class="el-icon-upload" />
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div slot="tip" class="el-upload__tip">
          只能上传.xls与.xlsx文件
        </div>
      </el-upload>
      <span slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="submit">上传</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { importStyle } from '@/api/service-management'
export default {
  props: {
    importVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      submitLoading: false,
      form: {
        siteId: 1
      },
      formRules: {}
    }
  },
  methods: {
    downloadTemplate() {
      window.open('https://mmt-sz.oss-cn-shenzhen.aliyuncs.com/template/%E8%B4%A2%E5%8A%A1%E5%AE%9A%E4%B9%89%E6%96%B0%E5%93%81style.xlsx', '_blank')
    },
    handleExceed() {
      this.$message.warning('只能上传1个文件')
    },
    cancel() {
      this.$refs?.uploadExcel.clearFiles()
      this.$emit('update:importVisible', false)
    },
    async submit() {
      const { uploadFiles } = this.$refs.uploadExcel
      if (!uploadFiles[0]) {
        this.$message.warning('请上传文件')
        return
      }
      const excel = uploadFiles[0].raw
      const uploadParams = new FormData()
      uploadParams.set('file', excel)

      this.submitLoading = true
      const { success, msg } = await importStyle(uploadParams).finally(() => {
        this.submitLoading = false
      })
      if (success) {
        this.$message.success(msg || '导入成功')
        this.cancel()
        this.$emit('refresh')
      } else {
        this.$message.error(msg || '导入失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
