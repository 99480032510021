// Style信息查询
import request from '@/utils/request'

/**
 * @typedef {Object} platformAttrs
 * @property {number} id id
 * @property {number} platformId 平台id
 * @property {string} platformCode 平台code
 * @property {string} platformName 平台名称
 */

/**
 * 根据userId获取平台列表
 * @param {Object} data
 * @param {string | number} [data.userId] 用户id
 * @returns {Promise<platformAttrs[]>} PlatformList 平台列表
 */
export function getPlatformListByUser(data) {
  return request({
    url: `${process.env.VUE_APP_ERP_API}erp-api/base/get_user_plat`,
    method: 'post',
    data
  })
}

/**
 * @typedef {Object} siteAttrs
 * @property {number} id id
 * @property {number} siteId 站点id
 * @property {string} siteCode 站点code
 * @property {string} siteName 站点名称
 */

/**
 * 根据条件查询站点列表
 * @param data
 * @param [data.buId] {string[] | number[]} buId
 * @param [data.groupId] {string[] | number[]} groupId
 * @param [data.platformCode] {string[] | number[]} 平台code
 * @param [data.userIdList] {string[] | number[]} 用户id
 * @param [data.platformId] {string[] | number[]} 平台id
 * @returns {Promise<siteAttrs[]>} SiteList 站点列表
 */
export function getSiteList(data) {
  return request({
    url: `${process.env.VUE_APP_ERP_API}erp-api/base/get_site`,
    method: 'post',
    data
  })
}

/**
 * @typedef {Object} shopAttrs
 * @property {number} shopId 店铺id
 * @property {string} shopCode 店铺code
 * @property {string} shopName 店铺名称
 */

/**
 * 根据条件查询店铺列表
 * @param data
 * @param [data.buId] {string[] | number[]} buId
 * @param [data.groupId] {string[] | number[]} groupId
 * @param [data.platformCode] {string[] | number[]} 平台code
 * @param [data.userIdList] {string[] | number[]} 用户id
 * @param [data.platformId] {string[] | number[]} 平台id
 * @param [data.siteId] {string[] | number[]} 站点id
 * @param [data.siteCode] {string[] | number[]} 站点code
 * @returns {Promise<shopAttrs[]>} ShopList 店铺列表
 */
export function getShopList(data) {
  return request({
    url: `${process.env.VUE_APP_ERP_API}erp-api/base/shop`,
    method: 'post',
    data
  })
}

/**
 * @typedef {Object} cascadeAttrs
 * @property {string} label
 * @property {string | number} value
 * @property {cascadeAttrs[]} children
 */

/**
 * 根据userId获取平台/站点/店铺级联列表数据
 * @param [params]
 * @param [params.userId] {string | number} 用户id
 * @returns {Promise<cascadeAttrs[]>}
 */
export function getUserPlatformSiteShop(params) {
  return request({
    url: `${process.env.VUE_APP_ERP_API}erp-api/basicInformation/getUserPlatformSiteShop`,
    method: 'get',
    params
  })
}

/**
 * @typedef {Object} styleAttrs
 * @property {number} styleId
 * @property {string} styleName
 */

/**
 * 根据条件查询Style列表
 * @param data
 * @param [data.buId] {string[] | number[]} buId
 * @param [data.groupId] {string[] | number[]} groupId
 * @param [data.platformCode] {string[] | number[]} 平台code
 * @param [data.userIdList] {string[] | number[]} 用户id
 * @param [data.platformId] {string[] | number[]} 平台id
 * @param [data.siteId] {string[] | number[]} 站点id
 * @param [data.siteCode] {string[] | number[]} 站点code
 * @param [data.shopId] {string[] | number[]} 店铺id
 * @returns {Promise<styleAttrs[]>} styleList style列表
 */
export function getStyleList(data) {
  // return request({
  return request({
    // url: `api/basicInformation/new_style_list`,
    url: `${process.env.VUE_APP_ERP_API}erp-api/base/new_style_list`,
    method: 'post',
    data
  })
}

/**
 * @typedef {Object} sizeAttrs
 * @property {string} size
 */

/**
 * 根据styleId获取size列表
 * @param params
 * @param [params.styleIdList] {string[] | number[]}
 * @returns {Promise<sizeAttrs[]>}
 */
export function getSizeListByStyleId(params) {
  return request({
    url: `${process.env.VUE_APP_ERP_API}erp-api/basicInformation/getSizeByStyleId`,
    params
  })
}

/**
 * @typedef {Object} colorAttrs
 * @property {string} color
 */

/**
 * 根据styleId获取color列表
 * @param params
 * @param [params.styleIdList] {string[] | number[]}
 * @returns {Promise<colorAttrs[]>}
 */
export function getColorListByStyleId(params) {
  return request({
    url: `${process.env.VUE_APP_ERP_API}erp-api/basicInformation/getColorByStyleId`,
    params
  })
}

/**
 * @typedef {Object} skuAttrs
 * @property {string} sku
 * @property {string} styleId
 * @property {string} styleName
 * @property {string} color
 * @property {string} size
 */

/**
 * 根据style/color/size获取sku列表
 * @param data
 * @param [data.styleIdList] {string[] | number[]}
 * @param [data.colorList] {string[] | number[]}
 * @param [data.sizeList] {string[] | number[]}
 * @returns {Promise<skuAttrs[]>}
 */
export function getSKUList(data) {
  return request({
    url: `${process.env.VUE_APP_ERP_API}erp-api/base/getSkuByInfo`,
    method: 'post',
    data
  })
}

export function getParentAsin(params) {
  return request({
    url: `${process.env.VUE_APP_ERP_API}erp-api/basicInformation/getParentAsin`,
    method: 'get',
    params
  })
}

export function queryStyleList() {
  return request({
    url: `/base/basestyle/list`,
    method: 'get'
  })
}

// Size信息查询
export function querySizeList(params) {
  return request({
    url: `/base/basesize/list`,
    method: 'get',
    params
  })
}

// Color信息查询
export function queryColorList(params) {
  return request({
    url: `/base/basecolor/list`,
    method: 'get',
    params
  })
}
